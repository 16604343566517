import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { LazyIconChevronLeft } from '@/design-system/atoms/Icons/IconChevronLeft/Lazy';
import { LazyIconChevronRight } from '@/design-system/atoms/Icons/IconChevronRight/Lazy';

import { useCarouselContext } from '../../contexts/CarouselContext';
import styles from './Navigation.module.scss';

export const Navigation = React.forwardRef((props, ref) => {
    const {
        as: Element = 'div',
        className,
        buttonLeftRef,
        buttonRightRef,
        buttonLeftClassName,
        buttonRightClassName,
        buttonLeftIconLabel = 'scroll carousel to left',
        buttonRightIconLabel = 'scroll carousel to right',
        analytics,
        ...otherProps
    } = props;

    const {
        context: { contentId, emblaApi },
    } = useCarouselContext();

    const [navigateLeft, setNavigateLeft] = React.useState(false);
    const [navigateRight, setNavigateRight] = React.useState(false);

    const navigationRef = React.useRef(navigateLeft || navigateRight);
    React.useEffect(() => {
        navigationRef.current = navigateLeft || navigateRight;
    }, [navigateLeft, navigateRight]);

    React.useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const handleShowNavigation = () => {
            const newNavigateLeft = emblaApi.canScrollPrev();
            const newNavigateRight = emblaApi.canScrollNext();

            setNavigateLeft(newNavigateLeft);
            setNavigateRight(newNavigateRight);
        };

        handleShowNavigation();
        emblaApi.on('reInit', handleShowNavigation);
        emblaApi.on('select', handleShowNavigation);
        emblaApi.on('resize', handleShowNavigation);

        return () => {
            emblaApi.off('reInit', handleShowNavigation);
            emblaApi.off('select', handleShowNavigation);
            emblaApi.off('resize', handleShowNavigation);
        };
    }, [emblaApi]);

    const handleNavigateLeftClick = React.useCallback(
        (e) => {
            e.preventDefault();
            emblaApi?.scrollPrev();
        },
        [emblaApi],
    );

    const handleNavigateRightClick = React.useCallback(
        (e) => {
            e.preventDefault();
            emblaApi?.scrollNext();
        },
        [emblaApi],
    );

    if (!navigateLeft && !navigateRight) {
        return null;
    }

    return (
        <Element
            ref={ref}
            aria-label="carousel navigation"
            aria-controls={contentId}
            className={classnames(styles['navigation'], className)}
            {...otherProps}
        >
            <Button
                ref={buttonLeftRef}
                className={buttonLeftClassName}
                buttonStyle={Button.STYLE.TERTIARY}
                ariaLabel={buttonLeftIconLabel}
                iconComponent={LazyIconChevronLeft}
                disabled={!navigateLeft}
                onClick={handleNavigateLeftClick}
                analytics={
                    analytics
                        ? {
                              ...analytics,
                              actionLabel: 'previous_item',
                              interactionType: 'previous_button',
                          }
                        : undefined
                }
            />
            <Button
                ref={buttonRightRef}
                className={buttonRightClassName}
                buttonStyle={Button.STYLE.TERTIARY}
                ariaLabel={buttonRightIconLabel}
                iconComponent={LazyIconChevronRight}
                disabled={!navigateRight}
                onClick={handleNavigateRightClick}
                analytics={
                    analytics
                        ? {
                              ...analytics,
                              actionLabel: 'next_item',
                              interactionType: 'next_button',
                          }
                        : undefined
                }
            />
        </Element>
    );
});

Navigation.displayName = 'Navigation';

Navigation.propTypes = {
    /**
     * Element Type of the root element
     */
    as: PropTypes.elementType,
    /**
     * React Ref of left button
     */
    buttonLeftRef: PropTypes.object,
    /**
     * React Ref of right button
     */
    buttonRightRef: PropTypes.object,
    /**
     * ClassName for left button
     */
    buttonLeftClassName: PropTypes.string,
    /**
     * ClassName for right button
     */
    buttonRightClassName: PropTypes.string,
    /**
     * `aria-label` for left button
     */
    buttonLeftIconLabel: PropTypes.string,
    /**
     * `aria-label` for right button
     */
    buttonRightIconLabel: PropTypes.string,
};
