import { CallToAction } from './components/CallToAction';
import { Card } from './components/Card';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';
import { Root } from './components/Root';
import { Spacer } from './components/Spacer';

export const Carousel = {
    Root,
    Content,
    Card,
    Footer,
    CallToAction,
    Spacer,
    Navigation,
};
